













































import Vue from 'vue';

import Article from '../components/Article.vue';
import { Category, ContentStructure, Post } from '@/types/interface';
import { contentStructure } from '@/content/contentStructure';

export default Vue.extend({
  name: 'Blog',
  components: {
    Article,
  },
  data() {
    const posts: ContentStructure = contentStructure;
    return {
      data: posts,
      posts: [] as Post[],
      categories: [] as Category[],
      currentTopics: [] as string[], // Store the tags for the selected category
      selectedCategory: '', // Track selected category
      filteredPosts: [] as Post[],
      currentCategory: '' as string,
      selectedTopicIndex: null as number | null,
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const posts = this.getPosts();
      this.posts = posts;
      this.filteredPosts = posts;
      this.categories = this.getCategories();
    },
    getPosts(): Post[] {
      const posts = [];
      for (const category of this.data.categories) {
        for (const post of category.posts) {
          posts.push(post);
        }
      }

      const sortedPosts = posts.sort((a, b) => {
        const dateA = this.parseDate(a.date);
        const dateB = this.parseDate(b.date);

        return dateB.getTime() - dateA.getTime();
      });

      return sortedPosts;
    },
    parseDate(dateString: string): Date {
      const [day, month, year] = dateString.split('/').map(Number);
      return new Date(year, month - 1, day);
    },
    getCategories(): Category[] {
      return this.data.categories;
    },
    // Fetch topics when a category is clicked
    async fetchCategoryTopics(category: Category, index: number) {
      if (category.name === this.currentCategory) {
        this.resetSelection();
        return;
      }
      this.selectedTopicIndex = index; // Update selected topic index
      try {
        // const tagId: any = category.topics;

        // Fetch tags using the tag IDs
        const topics = category.topics;
        this.currentTopics = topics;
        this.selectedCategory = category.name; // Set the selected category
        this.currentCategory = category.name;
        // console.log(this.selectedCategory);
        this.filterPosts();
      } catch (err) {
        console.error('Error fetching category tags:', err);
      }
    },
    filterPosts() {
      if (!this.selectedCategory) {
        // No category selected, show all posts
        this.filteredPosts = this.posts;
        return;
      }

      // Filter posts by selected category
      const category = this.categories.find(
        cat => cat.name === this.selectedCategory,
      );
      if (category) {
        this.filteredPosts = category.posts;
      }
    },
    resetSelection() {
      this.selectedCategory = '';
      this.currentCategory = '';
      this.currentTopics = [];
      this.selectedTopicIndex = null;
      this.filteredPosts = this.posts;
    },
  },
});







































import Vue from 'vue';

export default Vue.extend({
  name: 'Article',
  props: {
    img: Object,
    title: String,
    tags: Array,
    date: String,
    read: String,
  },
  data() {
    return {
      loading: true,
      post: {} as any,
    };
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    goToPost() {
      this.$router.push({
        name: 'post',
        params: {
          postId: this.img.name,
        },
      });
    },
  },
});
